import { useCallback, useEffect, useState } from "react";
import Color from "colorjs.io";
import {
  Container,
  Typography,
  TextField,
  Button,
  Stack,
  Box,
  Grid,
} from "@mui/material";
import { ContentCopy, Done } from "@mui/icons-material";

import { colors } from "../../constants/colors";

const copyButtonStates = {
  copy: {
    icon: <ContentCopy />,
    text: "Copy"
  },
  copied: {
    icon: <Done />,
    text: "Copied!"
  },
};

const getRandomColors = (limit) => {
  const keys = Object.keys(colors);
  const shuffledKeys = keys.sort(() => 0.5 - Math.random());
  const selectedKeys = shuffledKeys.slice(0, limit);
  return selectedKeys.map((key) => ({
    color: colors[key],
    name: key,
  }));
}

export const HexConverter = () => {
    const [oklch, setOklch] = useState(null);
    const [hex, setHex] = useState("");
    const [convertedHex, setConvertedHex] = useState(null);
    const [error, setError] = useState(null);
    const [history, setHistory] = useState(
      JSON.parse(localStorage.getItem("history")) || []
    );
    const [copyButton, setCopyButton] = useState(copyButtonStates.copy);
    const [randomColors] = useState(getRandomColors(10));

    const initializeHistory = useCallback(() => {
      if (history.length > 0) {
        const fallbackHex = history[0];
        const oklch = new Color(fallbackHex).to("oklch");
        setConvertedHex(fallbackHex);
        setOklch(oklch);
      }
    }, [history]);

    useEffect(() => {
      localStorage.setItem("history", JSON.stringify(history));
      initializeHistory();
    }, [history, initializeHistory]);

    const addToHistory = (color) => {
      const newHistory = history.filter((item) => item !== color);

      if (newHistory.length > 9) {
        newHistory.pop();
      }

      setHistory([color, ...newHistory]);
    };

    const handleHexChange = (value) => {
      setHex(value);
    };

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleConvertClick();
      }
    };

    const handleConvertClick = () => {
      let finalHex = hex;

      if (!finalHex) {
        finalHex = "#000000";
      }

      if (!finalHex.startsWith("#")) {
        finalHex = `#${finalHex}`;
      }

      if (finalHex.length < 7) {
        finalHex = finalHex.padEnd(7, "0");
      }

      if (finalHex.length > 7) {
        finalHex = finalHex.slice(0, 7);
      }

      try {
        const oklch = new Color(finalHex).to("oklch");
        setError(null);
        setHex(finalHex.toUpperCase());
        setConvertedHex(finalHex.toUpperCase());
        setOklch(oklch);
        addToHistory(finalHex.toUpperCase());
      } catch {
        setOklch(null);
        setConvertedHex(null);
        setError("Invalid HEX string, example: #000000");
        initializeHistory();
      }
    };

    const handleHistoryClick = (color) => {
      const oklch = new Color(color).to("oklch");
      setConvertedHex(color.toUpperCase());
      setOklch(oklch);
    }

    const handleW3CClick = (color) => {
      addToHistory(color.toUpperCase());
    }

    const handleCopyClick = () => {
      navigator.clipboard.writeText(formatOklch(oklch));
      setCopyButton(copyButtonStates.copied);
      setTimeout(() => {
        setCopyButton(copyButtonStates.copy);
      }, 1000);
    };

    const formatOklch = (oklch) => {
      const h = oklch.h.toFixed(3);
      return `oklch(${(oklch.l * 100).toFixed(2)}% ${oklch.c.toFixed(2)} ${isNaN(h) ? 0 : h})`;
    };

    const handleClearHistoryClick = () => {
      setHistory([]);
    };

    return (
      <>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            HEX to OKLCH Color Converter
          </Typography>

          <Container maxWidth="xs" sx={{ mt: 6 }}>
            <Stack direction="column" spacing={2}>
              <TextField
                id="hex-string"
                autoFocus
                label="HEX string color"
                placeholder="#000000"
                variant="outlined"
                value={hex}
                onChange={(event) => {
                  handleHexChange(event.target.value);
                }}
                onKeyDown={handleKeyPress}
                helperText={error}
                error={error}
                inputProps={{ maxLength: 7 }}
              />
              <Button onClick={handleConvertClick} variant="contained">Convert to OKLCH</Button>
            </Stack>
          </Container>

          {oklch && (
            <Stack sx={{ py: 3 }} direction="column" alignItems="center" spacing={2}>
              <Stack alignItems="center">
                <Typography variant="caption">
                  {convertedHex}
                </Typography>
                <Box sx={{ borderRadius: 2, boxShadow: 3, backgroundColor: convertedHex, width: 192, height: 108 }} />
              </Stack>
              <Typography>Converts to:</Typography>
              <Stack direction={{ md: "row" }} spacing={2} alignItems="center">
                <Typography variant="h5" gutterBottom>
                  <strong>{oklch && formatOklch(oklch)}</strong>
                </Typography>
                <Button variant="outlined" startIcon={copyButton.icon} onClick={handleCopyClick}>{copyButton.text}</Button>
              </Stack>
            </Stack>
          )}

          {history.length > 0 && (
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography>
                Your history:
              </Typography>
              <Grid container justifyContent="center">
                {history.map((color) => (
                  <Grid key={color} sx={{ px: 1, pt: 2 }} item>
                    <Stack alignItems="center" spacing={1}>
                      <Box onClick={() => handleHistoryClick(color)} sx={{ p: 3, boxShadow: 3, borderRadius: 12, backgroundColor: color, width: 12, height: 12 }} />
                      <Typography variant="caption">{color}</Typography>
                    </Stack>
                  </Grid>
                ))}
                <Button onClick={handleClearHistoryClick} size="small">clear history</Button>
              </Grid>
            </Stack>
          )}

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            Try to convert some W3C colors
          </Typography>

          <Grid container justifyContent="center" sx={{ mb: 3 }}>
            {randomColors.map(({color, name }) => (
              <Grid key={color} sx={{ px: 1, pt: 2 }} item>
                <Box sx={{ minWidth: 100 }}>
                  <Stack alignItems="center" spacing={1}>
                    <Box onClick={() => handleW3CClick(color)} sx={{ p: 3, boxShadow: 3, borderRadius: 12, backgroundColor: color, width: 12, height: 12 }} />
                    <Typography variant="caption">{name}</Typography>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Typography variant="h5" gutterBottom>
            What does this HEX to OKLCH converter do?
          </Typography>

          <Typography sx={{ mb: 3 }}>
            It takes input in the form of a HEX string color and then it converts that hexadecimal string to specify the same color in OKLCH notation. The vast majority of web apps CSS's are representing colors in HEX format and therefore if you would like to update the colors in your CSS to use the new OKLCH notation you will have to get the OKLCH representation of the HEX string. This tool allows you to get the correct OKLCH representation of your HEX color string.
          </Typography>

          <Typography variant="h5" gutterBottom>
            What is OKLCH and why should I start using it?
          </Typography>

          <Typography sx={{ mb: 3 }} component="div">
            In essence, oklch() introduces a new approach to define CSS colors. The format, oklch(L C H) or oklch(L C H / a), breaks down into distinct components:
            <ul>
              <li><strong>L</strong> denotes perceived lightness on a scale of 0% to 100%, ensuring consistent brightness perception.</li>
              <li><strong>C</strong> represents chroma, ranging from grayscale to the most vibrant color.</li>
              <li><strong>H</strong> signifies the hue angle within the range of 0 to 360.</li>
              <li><strong>a</strong> denotes opacity, expressed as a value between 0 and 1 or as a percentage from 0% to 100%.</li>
            </ul>
            The advantages of utilizing OKLCH are manifold:
            <ol>
              <li><strong>Automated Color Palette Generation:</strong> OKLCH liberates designers from the manual selection of each color. By defining a formula and choosing a few colors, a comprehensive design system palette is effortlessly generated.</li>
              <li><strong>Compatibility with Wide-Gamut P3 Colors:</strong> OKLCH extends its applicability to wide-gamut P3 colors, accommodating newer devices with enhanced color display capabilities, such as those from Apple.</li>
              <li><strong>Superior Color Modification and Palette Generation:</strong> In contrast to hsl(), OKLCH excels in color modifications and palette generation. Its utilization of perceptual lightness eliminates unexpected outcomes, addressing issues like those encountered with darken() in Sass.</li>
              <li><strong>Enhanced Accessibility with Predictable Lightness:</strong> OKLCH, owing to its predictable lightness, contributes to improved accessibility (a11y), ensuring consistent visual experiences.</li>
              <li><strong>Human-Readable Representation:</strong> Unlike rgb() or hex (#ca0000), OKLCH values are human-readable. The numbers provide a quick and clear understanding of the represented color, offering a readability advantage over HSL. OKLCH functions similarly to HSL but excels in encoding lightness more effectively.  </li>
            </ol>
          </Typography>

          <Typography variant="h5" gutterBottom>
            What W3C colors mean?
          </Typography>

          <Typography sx={{ mb: 3 }}>
            W3C named colors are a standardized set of color names defined by the World Wide Web Consortium. These named colors make it easy for web developers to specify colors in CSS, providing a consistent and convenient way to ensure uniformity across different platforms. Instead of using hexadecimal or RGB values, developers can use names like "aquamarine" or "darkorchid," simplifying code, improving readability, and contributing to a more accessible web design. This standardization enhances the overall user experience by promoting consistency and predictability in color representation across various browsers and devices. You can take a look at the whole list <a href="https://www.w3.org/wiki/CSS/Properties/color/keywords">here</a>.
          </Typography>
        </Container>
      </>
    );
  };
