import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { AppBar, Box, Button, Container, CssBaseline } from '@mui/material';

import { HexConverter } from './pages/hex-converter/hex-converter';
// import { PaletteGenerator } from './pages/palette-generator/palette-generator';

function App() {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <AppBar position="static" sx={{ mb: 3 }}>
          <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                LinkComponent={Link}
                to="/"
              >
                HEX Color Converter
              </Button>
              {/* <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                LinkComponent={Link}
                to="/palette-generator"
              >
                OKLCH Palette Generator
              </Button> */}
            </Box>
          </Container>
        </AppBar>
        <Routes>
          <Route path="/" Component={HexConverter} />
          {/* <Route path="/palette-generator" Component={PaletteGenerator} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
